
.splash-page {

  margin: 80px auto;
  text-align:center;
  width:100%;

  .second-part {
    text-align: left;
    display: inline-block;
  }

  li {
    list-style: none;
  }

  .gif-space {
    margin: 40px 0;
  }

  .demo-gif {

    border:1px solid #;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .early-access {
    color: #34495E;
    font-size: 20px;
    margin: 30px;
  }

  .bottom {
    margin: 20px;
    font-size: 20px;
  }

}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:both;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fade-in.one {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.fade-in.two {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay:1.7s;
  animation-delay: 1.7s;
}

.fade-in.three {
  -webkit-animation-delay: 2.6s;
  -moz-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

/*---make a basic box ---*/
.box{
  width: 200px;
  height: 200px;
  position: relative;
  margin: 10px;
  float: left;
  border: 1px solid #333;
  background: #999;

}

